import { Stack } from '@mui/joy';
import { Bucket } from 'components/Bucket';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store/StoreContext';
import { AccountProp } from 'types/account/account';
import { AccountFollowedTraders } from './AccountFollowedTraders';
import { TradeSettings } from './TradeSettings';

export const AccountDetails = observer(({ account, account: { balanceStats } }: AccountProp) => {
  const { accounts, leaderboard } = useStore();
  const isDataInitiallyFetched = accounts.isInitiallyFetched && leaderboard.isInitiallyFetched;

  return (
    <Stack gap={3}>
      {balanceStats && (
        <Stack gap={3} direction="row">
          <Bucket label="Total balance" value={balanceStats.equity} />
          <Bucket label="Available balance" value={balanceStats.balance} />
          <Bucket label="Inital Margin" value={balanceStats.initialMarginRisk} />
          <Bucket label="Max margin" value={balanceStats.maxMarginRisk} />
        </Stack>
      )}
      <TradeSettings account={account} tradeSettings={account.mainTradeSettings} />
      {isDataInitiallyFetched && <AccountFollowedTraders account={account} />}
    </Stack>
  );
});
