import { TradeSettings } from 'components/AccountDetails/TradeSettings';
import { ContentStandardLayout } from 'components/Layouts/ContentStandardLayout';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useStore } from 'store/StoreContext';

export const TraderTradeSettingsPage = observer(() => {
  const { leaderboard, accounts } = useStore();
  const { accountId, traderId } = useParams<{ accountId: string; traderId: string; }>();

  if (!accountId || !traderId) {
    return null;
  }

  const trader = leaderboard.findTraderById(traderId);
  const { account, tradeSettings } = accounts.findAccountAndTradeSettingsById(accountId, traderId);

  if (!account || !trader) {
    return null;
  }

  return (
    <ContentStandardLayout title="Individual trader settings" subTitle={`${account.name} / ${trader.name}`}>
      <TradeSettings
        account={account}
        tradeSettings={tradeSettings}
        trader={trader}
      />
    </ContentStandardLayout>
  );
});
