import { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { FollowedTraders } from 'pages/FollowedTraders';
import { Login } from 'pages/Login';
import { Signup } from 'pages/Signup';
import { TradeEvents } from 'pages/TradeEvents';
import { TraderDetails } from 'pages/TraderDetails';
import { AuthorisedRoute } from './AuthorisedRoute';
import { LoggedInLayout } from 'components/Layouts/LoggedInLayout';
import { Accounts } from 'pages/Accounts';
import { Account } from 'pages/Account';
import { TraderTradeSettingsPage } from 'pages/TraderTradeSettings';


export const MasterRouter: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path="*"
        element={<Navigate to="/main" replace />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route
        path="/main" element={(
          <AuthorisedRoute>
            <LoggedInLayout />
          </AuthorisedRoute>
        )}
      >
        <Route
          path="followed-traders"
          element={<FollowedTraders />}
        />
        <Route
          path="trade-events" element={<TradeEvents />}
        />
        <Route
          path="accounts" element={<Accounts />}
        />
        <Route
          path="account/:id" element={<Account />}
        />
        <Route
          path="account/:accountId/trader/:traderId" element={<TraderTradeSettingsPage />}
        />
        <Route
          path="trader-positions/:id" element={<TraderDetails />}
        />
      </Route>
    </Routes>
  </BrowserRouter>
);
